.button {
    background-color: transparent;
    outline: none !important;
    border: none !important;
}

.button:hover {
    background-color: rgba(255,255,255,0.2);
}

.button:active {
    background-color: rgba(0,0,0,0.1);
}