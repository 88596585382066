.nav {
    background-color: #9B9B9B;
    font-size: 14px;
    width: 330px;
    flex-shrink: 0;
}

.nav ul {
    padding: 0;
    background-color: #293C99;
}

.nav > ul > li {
    list-style: none;
}

.nav ul li > button {
    padding: 13px;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    outline: none;
    background-color: transparent;
    text-align: left;
    text-overflow: ellipsis;
    white-space:nowrap;
}

.nav ul li > button > i {
    margin-right: 10px;
}

.nav > ul > li:not(.active):hover {
    background-color: rgba(0,0,0,0.2);
}

.nav > ul > li:not(.active):active {
    background-color: rgba(0,0,0,0.4);
}

.nav ul li:last-child > button {
    border-bottom: none;
}

.nav ul li > ul {
    background-color: #F0F0F0;
}

.nav > ul > li > ul > a {
    color: #9b9b9b;
}

.nav > ul > li > ul > a:hover {
    text-decoration: none;
    color: #6E6E6E;
}

.nav > ul > li > ul > a:active {
    color: #B3B3B3;
}

.nav ul li > ul li {
    padding: 13px;
    margin-left: 10px;
    margin-right: 10px;

    font-weight: bold;
    text-transform: uppercase;
    list-style: none;

    border-bottom: 1px solid rgba(0,0,0,0.3);
}

.nav > ul > li > ul > a:last-child > li {
    border-bottom: none;
}

.nav > ul > li > ul > a > li.active {
    color: var(--primary);
}