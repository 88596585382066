.MuiTypography-root {
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.MuiInputBase-input:not(.MuiInputBase-inputMultiline) {
    font: inherit !important;
    letter-spacing: inherit !important;
    color: currentColor !important;
    padding: 4px 0 5px !important;
    border: 0 !important;
    box-sizing: content-box !important;
    background: none !important;
    height: 1.4375em !important;
    margin: 0 !important;
    -webkit-tap-highlight-color: transparent !important;
    min-width: 0 !important;
    -webkit-animation-name: mui-auto-fill-cancel !important;
    animation-name: mui-auto-fill-cancel !important;
    -webkit-animation-duration: 10ms !important;
    animation-duration: 10ms !important;
}

.MuiOutlinedInput-root .MuiInputBase-input {
    padding: 16px 0 16px 14px !important;
}

.MuiFilledInput-root .MuiInputBase-input {
    padding: 25px 12px 8px 0px !important;
}

.fa-fw {
    width: 1.06em;
}

.button-description {
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
}

.button-description-text {
    text-transform: uppercase;
    font-size: 50%;
    font-weight: bold;
    margin-bottom: 0px;
}

.close-button {
    border: none;
    padding: 0;
    background: transparent
}

.details-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.dropzone:hover {
    border-color: #1565C0 !important;
}

.pageLoader {
    border: 16px solid #B8B8B8; /* Light grey */
    border-top: 16px solid #1565C0; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}