.table {
    width: 100%;
    font-size: 14px;
    table-layout: auto !important;
}

.table > table > tbody > tr > td {
    background-color: white;
    height: 44px;
}

.table tr > td, .table tr > th {
    padding: 0 20px;
    max-width:100%;
    width: auto;
}

.table tr > td.min, .table  tr > th.min {
    white-space:nowrap;
    width: 1%;
}

.table tr > td, .table tr:not(:last-child) > th {
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.table tr > th {
    text-transform: uppercase;
}

.table > div {
    margin-top: 10px;
    float: right;
}

.table > div > button > i, .table > div > button {
    font-size: 18px !important;
}

.table > div > button > div {
    height: 10px;
    line-height: 12px;
}